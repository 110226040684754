<template>
  <div>
    <div class="">
        <Button
          class="btn-color1 px-4"
          v-b-modal.date-range-modal
        >
          <i class="fa fa-calendar"></i>
        </Button>
        <DateRangeModal
          @updated="handleDateUpdate"
          :startPeriod="startPeriod"
          :endPeriod="endPeriod"
        />
    </div>
  </div>
</template>

<script>

import Button from '@/components/common/Button/Button'
import DateRangeModal from '@/components/common/Modal/DateRangeModal'

export default {
  name: 'DateRangeInput',
  props: [
    'endPeriod', 'startPeriod', 'value'
  ],
  components: {
    Button,
    DateRangeModal
  },
  data () {
    return {
      local: ''
    }
  },
  computed: {
  },
  methods: {
    handleDateUpdate (dates) {
      this.$emit('input', dates)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
