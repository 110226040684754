<template>
  <div>
    <!-- Modal -->
    <b-modal
      id="date-range-modal"
      :modal-class="'default-modal'"
      :hide-header-close="true"
      :hide-footer="true"
      :size="'xl'
    ">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>{{ $t('date_range_modal_title') }}</span>
        </h2>
      </div>
      <div class="wrapper">
        <!-- Lateral com opções -->
        <div class="sidebar">
          <div v-for="(label, key) in filterButtons" :key="key">
              <button
              :class="{ selected: activeFilter === key }"
              @click="filterDate(key)"
              v-if="key !== 'max' || (key === 'max' && (startPeriod && endPeriod))"
            >
              {{ label }}
            </button>
          </div>
        </div>

        <!-- Calendários e Filtro de Data -->
        <div class="calendar-wrapper">
          <!-- Calendários -->
          <div class="calendar-container">
            <div>
              <div class="date-display">{{ $t('start_date') }}: {{ startDisplay }}</div>
              <div class="calendar">
                <div class="calendar-header">
                  <button @click="changeMonth('start', -1)">&#60;</button>
                  <span class="highlighted-color1">{{ calendarTitles.start }}</span>
                  <button @click="changeMonth('start', 1)">&#62;</button>
                </div>
                <div class="days-grid">
                  <!-- Dias da semana -->
                  <div v-for="(day, index) in weekDays" :key="`start-header-${index}`" class="day-header">
                    {{ day }}
                  </div>
                  <!-- Dias do mês -->
                  <div v-for="(day, index) in startDays" :key="`start-${index}`" :class="day.classes"
                    @click="!day.inactive && selectDay('start', day.date)">
                    {{ day.text }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="date-display">{{ $t('end_date') }}: {{ endDisplay }}</div>
              <div class="calendar">
                <div class="calendar-header">
                  <button @click="changeMonth('end', -1)">&#60;</button>
                  <span class="highlighted-color1">{{ calendarTitles.end }}</span>
                  <button @click="changeMonth('end', 1)">&#62;</button>
                </div>
                <div class="days-grid">
                  <!-- Dias da semana -->
                  <div v-for="(day, index) in weekDays" :key="`end-header-${index}`" class="day-header">
                    {{ day }}
                  </div>
                  <!-- Dias do mês -->
                  <div v-for="(day, index) in endDays" :key="`end-${index}`" :class="day.classes"
                    @click="!day.inactive && selectDay('end', day.date)">
                    {{ day.text }}
                  </div>
                </div>
              </div>
              <div class="w-100 d-flex justify-content-end mt-4">
                <Button class="btn-sm btn-primary me-2" @click="closeModal">{{ $t('close') }}</Button>
                <Button class="btn-sm btn-color1 me-2" @click="submit">{{ $t('filter_action') }}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import moment from 'moment'

import Button from '@/components/common/Button/Button'

export default {
  name: 'DateRangeModal',
  props: ['startPeriod', 'endPeriod'],
  components: {
    Button
  },
  data () {
    return {
      activeFilter: '',
      calendars: {
        start: { year: new Date().getFullYear(), month: new Date().getMonth() },
        end: { year: new Date().getFullYear(), month: new Date().getMonth() }
      },
      filterButtons: {
        today: this.$t('today'),
        week: this.$t('this_week'),
        yesterday: this.$t('yesterday'),
        lastSevenDays: this.$t('last_seven_days'),
        lastFifteenDays: this.$t('last_fifteen_days'),
        lastThirtyDays: this.$t('last_thirty_days'),
        month: this.$t('this_month'),
        lastMonth: this.$t('last_month'),
        max: this.$t('max')
      },
      monthNames: [
        this.$t('months.january'),
        this.$t('months.february'),
        this.$t('months.march'),
        this.$t('months.april'),
        this.$t('months.may'),
        this.$t('months.june'),
        this.$t('months.july'),
        this.$t('months.august'),
        this.$t('months.september'),
        this.$t('months.october'),
        this.$t('months.november'),
        this.$t('months.december')
      ],
      selectedDates: { start: null, end: null },
      weekDays: [
        this.$t('days.sunday'),
        this.$t('days.monday'),
        this.$t('days.tuesday'),
        this.$t('days.wednesday'),
        this.$t('days.thursday'),
        this.$t('days.friday'),
        this.$t('days.saturday')
      ]
    }
  },
  created () {
    this.filterDate('lastThirtyDays')
    this.submit()
    console.log(this.startPeriod, this.endPeriod)
  },
  computed: {
    calendarTitles () {
      return {
        start: `${this.monthNames[this.calendars.start.month]} ${this.calendars.start.year}`,
        end: `${this.monthNames[this.calendars.end.month]} ${this.calendars.end.year}`
      }
    },
    endDays () {
      return this.generateDays(this.calendars.end, 'end')
    },
    endDisplay () {
      return this.selectedDates.end
        ? this.formatDate(this.selectedDates.end)
        : 'Não Selecionada'
    },
    startDays () {
      return this.generateDays(this.calendars.start, 'start')
    },
    startDisplay () {
      return this.selectedDates.start
        ? this.formatDate(this.selectedDates.start)
        : 'Não Selecionada'
    }
  },
  methods: {
    /**
     * Change month
    */
    changeMonth (type, offset) {
      const calendar = this.calendars[type]
      calendar.month += offset

      if (calendar.month < 0) {
        calendar.month = 11
        calendar.year--
      } else if (calendar.month > 11) {
        calendar.month = 0
        calendar.year++
      }
    },
    /**
     * Close modal
    */
    closeModal () {
      this.$bvModal.hide('date-range-modal')
    },
    /**
     * Filter period
    */
    filterDate (type) {
      const today = moment().startOf('day')
      this.selectedDates.end = today.toDate()
      if (type === 'today') {
        this.selectedDates.start = today.toDate()
      } else if (type === 'week') {
        this.selectedDates.start = today.clone().startOf('week').toDate()
        this.selectedDates.end = today.clone().endOf('week').toDate()
      } else if (type === 'yesterday') {
        this.selectedDates.start = today.clone().subtract(1, 'days').toDate()
        this.selectedDates.end = today.clone().subtract(1, 'days').toDate()
      } else if (type === 'lastSevenDays') {
        this.selectedDates.start = today.clone().subtract(7, 'days').toDate()
      } else if (type === 'lastFifteenDays') {
        this.selectedDates.start = today.clone().subtract(15, 'days').toDate()
      } else if (type === 'lastThirtyDays') {
        this.selectedDates.start = today.clone().subtract(30, 'days').toDate()
      } else if (type === 'month') {
        this.selectedDates.start = today.clone().startOf('month').toDate()
        this.selectedDates.end = today.clone().endOf('month').toDate()
      } else if (type === 'lastMonth') {
        this.selectedDates.start = today.clone().startOf('month').subtract(1, 'month').toDate()
        this.selectedDates.end = today.clone().startOf('month').subtract(1, 'month').endOf('month').toDate()
      } else if (type === 'max') {
        if (!this.startPeriod || !this.endPeriod) {
          return
        }
        this.selectedDates.start = moment(this.startPeriod).toDate()
        this.selectedDates.end = moment(this.endPeriod).toDate()
      } else {
        this.selectedDates.start = null
        this.selectedDates.end = null
      }
      this.syncCalendars()
    },
    /**
     * Format date
    */
    formatDate (date) {
      return date.toLocaleDateString('pt-BR')
    },
    /**
     * Generate days of calendar
    */
    generateDays (calendar, type) {
      const { year, month } = calendar
      const days = []
      const firstDay = new Date(year, month, 1).getDay()
      const daysInMonth = new Date(year, month + 1, 0).getDate()
      const prevMonthDays = new Date(year, month, 0).getDate()

      for (let i = firstDay - 1; i >= 0; i--) {
        days.push({
          text: prevMonthDays - i,
          inactive: true,
          classes: ['day', 'inactive']
        })
      }

      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(year, month, i)
        const isSelected =
          this.selectedDates[type] &&
          this.selectedDates[type].toDateString() === date.toDateString()
        days.push({
          text: i,
          inactive: false,
          date,
          classes: ['day', isSelected && 'selected'].filter(Boolean)
        })
      }

      const remainingDays = 42 - days.length
      for (let i = 1; i <= remainingDays; i++) {
        days.push({
          text: i,
          inactive: true,
          classes: ['day', 'inactive']
        })
      }

      return days
    },
    /**
     * Select specif day
    */
    selectDay (type, date) {
      this.selectedDates[type] = date
      this.activeFilter = null
    },
    /**
     * Submit
    */
    submit () {
      const startDate = this.selectedDates.start
      const endDate = this.selectedDates.end
      this.$emit('updated', { startDate, endDate })
      this.$bvModal.hide('date-range-modal')
    },
    /**
     * Sync position of calendars
    */
    syncCalendars () {
      if (this.selectedDates.start) {
        this.calendars.start.year = this.selectedDates.start.getFullYear()
        this.calendars.start.month = this.selectedDates.start.getMonth()
      }
      if (this.selectedDates.end) {
        this.calendars.end.year = this.selectedDates.end.getFullYear()
        this.calendars.end.month = this.selectedDates.end.getMonth()
      }
    }
  }
}
</script>

<style scoped>

.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  gap: 3em;
}

.sidebar {
  background-color: #fff;
  color: #000;
  width: 400px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(202, 202, 202, 0.357);
}

.sidebar button {
  background-color: #fff;
  border: none;
  color: #000;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  transition: .5s;
  border: none;
}

.sidebar button:hover {
  opacity: .8;
}

.calendar-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.calendar-header span {
  font-weight: bold;
}

.calendar-header button {
  background-color: #fff;
  border: none;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calendar-header button:hover {
  background-color: #fff;
}

.calendar-container {
  display: flex;
  gap: 20px;
}

.sidebar button.selected {
  background-color: #FFD600;
  color: #000;
}

.sidebar button:hover {
  background-color: #FFD600;
}

.date-display {
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #FFD600;
  margin-bottom: 1em;
  font-weight: bold;
}

.calendar {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #7f7e7945;
  height: 400px;
  position: relative;
}

.calendar h3 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.day {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #000;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.day:hover {
  background-color: #fff;
  transform: scale(1.1);
}

.day.selected {
  background-color: #FFD600;
  color: #000;
  font-weight: 600
}

.day.inactive {
  color: #777;
  cursor: not-allowed;
}

.day-header {
  font-weight: bold;
  color: #aaa;
  text-align: center;
}

#start-date-display {
  font-weight: bold;
  letter-spacing: .5px;
}

#end-date-display {
  font-weight: bold;
  letter-spacing: .5px;
}
</style>
